<template>
    <div class="editor">
        <div v-if="show1" id="toolbar-container"></div>
        <div v-if="show2" id="editor-container" class="editor-container"></div>
    </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css'
import { createEditor, createToolbar, IEditorConfig, IDomEditor, i18nChangeLanguage } from '@wangeditor/editor'
export default {
    name: 'Editor',
    props: ['content'],
    components: {
    },
    data () {
        return {
            editor: null,
            show1: false,
            show2: false
        }
    },
    methods: {
        init(){
            i18nChangeLanguage('en')
            const editorConfig = {}
            editorConfig.placeholder = 'Please input'
            const that = this
            editorConfig.onChange = (editor) => {
                // 当编辑器选区、内容变化时，即触发
                that.$emit('onContent', editor.getHtml())
            }
            // 创建编辑器
            this.editor = createEditor({
                html: this.content,
                selector: '#editor-container',
                config: editorConfig,
                mode: 'default' // 或 'simple' 参考下文
            })
            // 创建工具栏
            const toolbar = createToolbar({
                editor: this.editor,
                selector: '#toolbar-container',
                mode: 'default' // 或 'simple' 参考下文
            })
        }
    },
    created(){
        this.show1 = this.show2 = false
        this.show1 = this.show2 = true
    },
    mounted(){
        if(this.editor){
           this.editor.destroy()
           this.editor = null
        }
         this.init()
    },
    destroyed(){
        console.log('...')
        this.editor.destroy()
    }
}
</script>
<style lang="stylus" scoped>
.editor-container
    height 300px
    border 1px solid #eee
</style>